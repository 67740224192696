import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { PrimarySearchAppBar } from './AppBar'
import { engine } from './appEngine'
import { EnsureAuthenticated } from './EnsureAuthenticated'
import { Import } from './Import/Import'

const { store } = engine

const Root: FC = () => {
  return (
    <EnsureAuthenticated>
      <Router>
        <div>
          <PrimarySearchAppBar />
          <Switch>
            <Route path="/about">About</Route>
            <Route path="/users">Users</Route>
            <Route path="/" exact>
              Home
            </Route>
            <Route path="/import">
              <Import />
            </Route>
          </Switch>
        </div>
      </Router>
    </EnsureAuthenticated>
  )
}
function App() {
  return (
    <Provider store={store}>
      <Root />
    </Provider>
  )
}

export default App
