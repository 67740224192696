import { TableRow } from '@material-ui/core'
import { map } from '@s-libs/micro-dash'
import React, { FC } from 'react'
import { FieldKey } from '../store/Database'
import { CsvHeaderCell } from './CsvHeaderCell'

export const CsvHeader: FC<{
  row: string[]
  onFieldMappingChanged: (i: string, value: FieldKey) => void
}> = ({ row, onFieldMappingChanged }) => {
  return (
    <TableRow>
      {map(row, (v, i) => (
        <CsvHeaderCell
          key={i}
          initialValue={v}
          onValueChanged={(newValue) =>
            onFieldMappingChanged(i.toString(), newValue)
          }
        />
      ))}
    </TableRow>
  )
}
