import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AsyncState<T extends any> {
  data?: T
  error?: Error
  pending: boolean
}
export interface MainState {
  uid: AsyncState<string | null>
}
export const createMainSlice = () => {
  const initialState: MainState = {
    uid: { pending: true },
  }

  const slice = createSlice({
    name: 'main',
    initialState,
    reducers: {
      uidUpdated: (state, action: PayloadAction<string | null>) => {
        state.uid = { data: action.payload, pending: false }
      },
    },
  })

  const { actions, reducer } = slice

  return { actions, reducer }
}
