import React, { FC } from 'react'
import { Signin } from './Signin'
import { useAppSelector } from './store'

export const EnsureAuthenticated: FC = ({ children }) => {
  const uid = useAppSelector((state) => state.core.uid)

  if (!uid) {
    return <Signin />
  }
  return <>{children}</>
}
