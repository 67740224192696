import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core'
import Papa from 'papaparse'
import React, { FC, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAppDispatch } from '../store'
import { FieldMapping_InMemory } from '../store/Database'
import { FieldMapper } from './FieldMapper'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 20,
      padding: 10,
    },
    table: {
      width: 'initial',
    },
    uploader: {
      padding: 20,
      marginBottom: 20,
    },
  })
)

export const Import: FC = () => {
  const [csvData, setCsvData] = useState<string[][]>()
  const classes = useStyles()
  const { importCsvData } = useAppDispatch()
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      const contents = e.target?.result
      // console.log(contents)
      if (!contents) return
      const data = Papa.parse<string[]>(contents.toString())
      setCsvData(data.data)
    }
    reader.readAsText(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleImport = (mapping: FieldMapping_InMemory) => {
    if (!csvData) return
    importCsvData(csvData, mapping).catch(console.error)
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.uploader} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag {`'n'`} drop some files here, or click to select files</p>
        )}
      </Paper>
      {csvData && <FieldMapper csvData={csvData} onImport={handleImport} />}
    </div>
  )
}
