import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core'
import { map, reduce } from '@s-libs/micro-dash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  DEFAULT_FIELDS,
  FieldKey,
  FieldMapping_InMemory,
} from '../store/Database'
import { CsvHeader } from './CsvHeader'
import { CsvRow } from './CsvRow'
import { useStyles } from './Import'

export const FieldMapper: FC<{
  csvData: string[][]
  onImport: (mapping: FieldMapping_InMemory) => void
}> = ({ csvData, onImport }) => {
  const classes = useStyles()

  const [fieldMapping, setFieldMapping] = useState(
    csvData[0].reduce((c, v, i) => {
      const _v: FieldKey | '-' = (() => {
        if (DEFAULT_FIELDS[v]) return v
        return '-'
      })()
      c[i] = _v
      return c
    }, {} as FieldMapping_InMemory)
  )
  const calcAllMapped = useCallback(
    () => reduce(fieldMapping, (c, v, k) => c && v !== '-', true),
    [fieldMapping]
  )

  const [allMapped, setAllMapped] = useState(calcAllMapped())

  useEffect(() => {
    console.log('field mapping changed', fieldMapping)
    setAllMapped(calcAllMapped)
  }, [calcAllMapped, fieldMapping])

  const handleFieldMappingChanged = (i: string, value: FieldKey) => {
    setFieldMapping((state) => ({
      ...state,
      [i]: value,
    }))
  }

  const table = useMemo(() => {
    console.log('render')
    if (!csvData) return null

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <CsvHeader
              row={csvData[0]}
              onFieldMappingChanged={handleFieldMappingChanged}
            />
          </TableHead>
          <TableBody>
            {map(csvData.slice(1), (row, i) => (
              <CsvRow key={i} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }, [csvData, classes])

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={!allMapped}
        onClick={() => onImport(fieldMapping)}
      >
        Primary
      </Button>
      {table}
    </>
  )
}
