import { configureStore } from '@reduxjs/toolkit'
import { map } from '@s-libs/micro-dash'
import Bottleneck from 'bottleneck'
import { auth, db } from '../firebase'
import {
  DataRow,
  DEFAULT_FIELDS,
  FieldMapping_AtRest,
  FieldMapping_InMemory,
  FIELD_NAME_SKIP,
  FIELD_NAME_UNSELECTED,
} from './Database'
import { createMainSlice } from './mainSlice'

const limiter = new Bottleneck({ maxConcurrent: 100 })

export const createEngine = () => {
  const mainSlice = createMainSlice()
  const store = configureStore({
    reducer: { core: mainSlice.reducer },
  })

  const dispatch = store.dispatch.bind(store)
  const getState = store.getState.bind(store)
  const subscribe = store.subscribe.bind(store)

  const uidOrDie = (message: string) => {
    const { uid } = getState().core
    const { pending, error, data } = uid
    if (!data) {
      throw new Error(`UID expected: ${message} `)
    }
    return data
  }

  const actions = { ...mainSlice.actions }

  ;(() => {
    const unsub = subscribe(() => {
      const { uid } = getState().core
      const { pending, error, data } = uid
      if (pending) return
      if (error) {
        console.error(error)
        return
      }
      if (!data) return
      unsub()
      ;(async () => {
        const existingMappingsSnap = await db
          .ref(`fieldMappings/${uidOrDie('fieldMappings init fetch')}`)
          .once('value')
        const existingMappings = existingMappingsSnap.val() as
          | FieldMapping_AtRest
          | undefined

        const finalFieldMappings: FieldMapping_AtRest = {
          ...DEFAULT_FIELDS,
          ...existingMappings,
        }

        await db
          .ref(`fieldMappings/${uidOrDie('fieldMappings init save')}`)
          .set(finalFieldMappings)
      })().catch(console.error)
    })
  })()

  auth.onAuthStateChanged((user) => {
    dispatch(actions.uidUpdated(user?.uid ?? null))
  })

  const importCsvData = async (
    csvData: string[][],
    mapping: FieldMapping_InMemory
  ) => {
    const [header, ...data] = csvData
    console.log('importing', csvData, mapping)
    const imported = await Promise.all(
      map(data, async (row, i) => {
        const rec: DataRow = row.reduce((c, v, i) => {
          const fieldName = mapping[i]
          if (
            fieldName === FIELD_NAME_SKIP ||
            fieldName === FIELD_NAME_UNSELECTED
          )
            return c
          c[fieldName] = v
          return c
        }, {} as DataRow)
        const contactsRef = db.ref(`contacts/${uidOrDie('Importing csv rec')}`)
        return limiter.schedule(() => contactsRef.push(rec))
      })
    )
    console.log('imported', imported)
  }
  return { store, getState, dispatch: { importCsvData } }
}
