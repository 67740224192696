import { MenuItem, Select, TableCell } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { map } from '@s-libs/micro-dash'
import React, { FC, useState } from 'react'
import { DEFAULT_FIELDS, FieldKey } from '../store/Database'

export const CsvHeaderCell: FC<{
  initialValue: string
  onValueChanged: (v: FieldKey) => void
}> = ({ initialValue, onValueChanged }) => {
  const _v = (() => {
    if (DEFAULT_FIELDS[initialValue]) return initialValue
    return '-'
  })()

  const [value, setValue] = useState(_v)

  const handleChange: SelectInputProps['onChange'] = (e) => {
    // console.log(e)
    const v = e.target.value as FieldKey
    setValue(v)
    onValueChanged(v)
  }

  return (
    <TableCell component="th" scope="row">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={'-'}>{`<choose>`}</MenuItem>
        <MenuItem value={'x'}>{`<skip>`}</MenuItem>
        {map(DEFAULT_FIELDS, (info, k) => {
          return (
            <MenuItem key={k} value={k}>
              {info.label}
            </MenuItem>
          )
        })}
        <MenuItem>{`Add new...`}</MenuItem>
      </Select>
    </TableCell>
  )
}
