export type FieldKey = string

export type FieldMapping_AtRest = {
  [_ in FieldKey]: { label: string }
}

export const DEFAULT_FIELDS: FieldMapping_AtRest = {
  first: {
    label: 'First Name',
  },
  last: {
    label: 'Last Name',
  },
  street1: {
    label: 'Street 1',
  },
  street2: {
    label: 'Street 2',
  },
  city: {
    label: 'City',
  },
  state: {
    label: 'State',
  },
  zip: {
    label: 'Zip',
  },
  country: {
    label: 'Country',
  },
  phone: {
    label: 'Phone',
  },
  email: {
    label: 'Email',
  },
  facebook: {
    label: 'Facebook',
  },
  twitter: {
    label: 'Twitter',
  },
  instagram: {
    label: 'Instagram',
  },
  youtube: {
    label: 'YouTube',
  },
  twitch: {
    label: 'Twitch',
  },
  gender: {
    label: 'Gender',
  },
  ip: {
    label: 'IP Address',
  },
}

export const FIELD_NAME_UNSELECTED = '-'
export const FIELD_NAME_SKIP = 'x'
export type FieldMapping_InMemory = {
  [_: string]: FieldKey | typeof FIELD_NAME_UNSELECTED | typeof FIELD_NAME_SKIP
}

export type DataRow = { [_ in FieldKey]: string }
