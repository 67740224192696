import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyCeOb2ikNnx33nBb6ESVhqS3JmB6iX2AiE',
  authDomain: 'txthub-b7dc5.firebaseapp.com',
  databaseURL: 'https://txthub-b7dc5.firebaseio.com',
  projectId: 'txthub-b7dc5',
  storageBucket: 'txthub-b7dc5.appspot.com',
  messagingSenderId: '894402576556',
  appId: '1:894402576556:web:022cf386a1eefb85ee5f09',
  measurementId: 'G-JBFQMFNRTP',
}

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const db = firebase.database()

export { firebase, auth, db }

export default firebase
