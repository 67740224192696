import React, { FC } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { firebase } from './firebase'

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
}
export const Signin: FC = () => {
  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  )
}
