import { TableCell, TableRow } from '@material-ui/core'
import { map } from '@s-libs/micro-dash'
import React, { FC } from 'react'

export const CsvRow: FC<{ row: string[] }> = ({ row }) => {
  return (
    <TableRow>
      {map(row, (v, i) => (
        <TableCell key={i} component="th" scope="row">
          {v}
        </TableCell>
      ))}
    </TableRow>
  )
}
